/* EmailSubscription.css */

div {
  padding-left: 20px;
  padding-right:20px;
  align-items: center;
  justify-content: center;
}

.EmailWrapper {
  justify-content: center; /* This centers the flex items on the main-axis, which is horizontal due to row direction */
  align-items: center; /* This centers the flex items vertically within the container */
  border-radius: 8px;
}

h2 {    
  display: flex;
  place-items: center;
  font-size: 1.5em;
  justify-content: center;
}

.icon-container {
  padding: 12px;
  display: flex;
  justify-content: center;
  gap: 90px; /* Adjust gap between icons */
}

.icon-container img {
  width: 40px; /* Adjust icon size as needed */
  height: 70px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

 img{
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
 }

input[type="email"] {
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(to right, #007BFF, #0056b3);
}

button:hover {
  background: linear-gradient(to right, #0056b3, #003a7a);
}

p {
  margin-top: 10px;
  color: #333;
}

a {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  div {
    margin: 0px;
    padding: 0px;
  }

  h2 {
    font-size: 2em;
    text-align: center;

  }

  input[type="email"] {
    width: 70%;
  }

  button {
    padding: 8px 16px;
  }
}
