/* Header.css */
.app-header {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    width: 100%; /* Span the full width of the viewport */
}

.LogoWrapper {
    display: flex; /* Establishes this container as a flex container */
    flex-direction: row; /* Stacks child elements vertically */
    justify-content: space-between; /* Distributes space between items if the container has a defined height */
    align-items: center; /* Centers items horizontally in the container */
    gap: 30px; /* Keeps a specified gap between each item */
}

.header-content {
    display: flex;
    align-items: center;
}

.logo-container {
    position: sticky;
    left: 49.7%; /* Start at the middle of the screen */
    top: 50px; /* Align to the top of the viewport */
    transform: translateX(-50%); /* Shift it back by half its width to center */
    z-index: 1000; /* Make sure it's above other content */
}

@media (max-width: 1024px) 
{
    .LogoWrapper {
        display: flex; /* Establishes this container as a flex container */
        flex-direction: row; /* Stacks child elements vertically */
        justify-content: space-between; /* Distributes space between items if the container has a defined height */
        align-items: stretch; /* Centers items horizontally in the container */
        gap: 10px; /* Keeps a specified gap between each item */
    }
    .app-header {
        position: relative;
        padding-left: 0px;
        padding-top: 10px;
        padding-right: 0px;
        display: flex;
        flex-direction: column; /* Stacks child elements vertically */
        justify-content: space-between; /* Distributes space between items if the container has a defined height */
        gap: 10px; /* Keeps a specified gap between each item */


    }
    .LogoWrapper img {
        width: 20px; /* Adjust icon size as needed */
        height: auto;
      }
      .logo img {
        width: 20px; /* Adjust icon size as needed */
        height: auto;
      }
    
}
.logo img {
    width: 200px;
    height: auto !important;
}

.LogoWrapper img {
    width: 60px;
    height: auto;
  }

.navigation a {
    margin-right: 20px;
    text-decoration: none;
    color: #333; /* Or any other color */
}

.navigation a:hover {
    text-decoration: underline;
}

.icon-container img {
    height: 30px; /* Adjust icon size */
}