.container {
  width: 100%;
  padding: 0px;
  display: grid;
  place-items: center;
  margin:  auto;
  border-radius: 8px;
}

.app-header {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}

.custom-dropdown {
  width: 100%; /* Full width */
  padding: 10px; /* Some padding */
  margin: 10px 0; /* Some margin */
  display: inline-block; /* Default display */
  border: 1px solid #ccc; /* Border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Box sizing to handle padding */
}

.no-data-message {
  color: red;
}

@media (min-width: 600px) {
  .custom-dropdown {
    width: 100%; /* Width auto on larger screens */
  }}
/* Mobile-specific styles */
@media (max-width: 870px) {
  .container {
    width: 100%;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    gap: 50px; /* Adjust gap between icons */
    margin-top: 40px; /* Adjust margin as needed */
  }

  .customer-table, .tokens-table {
    width: 50%; /* Set to 100% to take the full width of its container */
    max-width: 200px; /* Set a maximum width to restrict the table size */
    border-collapse: collapse;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  
  .customer-table td, .tokens-table td, .tokens-table th {
    border: 1px solid #E0E0E0;
    padding: 3px;
    word-wrap: break-word; /* Allows the words to break and move to the next line */
    max-width: 100px; /* Set a max-width to control the width of the cells */
    overflow: hidden; /* Ensures the content is not overflowing the cell */
    text-overflow: ellipsis; /* Adds an ellipsis when the text overflows */
    white-space: nowrap; /* Keeps the text in a single line */
    font-size: 10.5px;
  }

  h2{
    margin-bottom: 10px;
  }

  .search-input {
    width: 50%;
  }
}
h1{
  margin-top: 0px;
}

.search-box {
  margin-top: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-input {
  padding: 10px;
  width: 300px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.search-button {
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: linear-gradient(to right, #4CAF50, #45a049);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background: linear-gradient(to right, #45a049, #388e3c);
}

.results {
  margin-top: 20px;
}

.customer-table, .tokens-table {
  width: 800px;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.customer-table td, .tokens-table td, .tokens-table th {
  border: 1px solid #E0E0E0;
  padding: 6px;
}

.tokens-table tr:nth-child(even) {
  background-color: rgba(72, 239, 128, 0.1);
}

.tokens-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background: linear-gradient(to right, #4CAF50, #45a049);
  color: white;
}

/* Smaller and more refined toggle switch */
/* Assuming this is the container that wraps the text and the toggle switch */
.sort-by-container {
  display: flex; /* This will align children (text and toggle switch) in a row */
  align-items: center; /* This will vertically center the children within the container */
  justify-content: flex-start; /* This will align children to the start of the container */
  gap: 10px; /* This creates some space between the children */
  margin: 0px 0px 10px 0px;
  padding-left: 5px;
}

/* Correct the toggle-switch class */
.toggle-switch {
  position: relative;
  width: 8px; /* Set an appropriate width for the toggle switch */
  height: 20px; /* Height to match the toggle switch */
  /* Removed vertical-align as it's not necessary with flexbox */
  margin: 0; /* Adjust margin if needed */
}

/* Other styles for the toggle switch remain the same */


.sort-order-toggle {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px; /* Full roundness */
}

.toggle-label:before {
  position: absolute;
  content: "";
  height: 15px; /* Slightly less than the switch container for a margin */
  width: 15px; /* Keep the knob circular */
  left: 6px;
  bottom: 2.5px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.sort-order-toggle:checked + .toggle-label {
  background-color: #4CAF50; /* Active color */
}

.sort-order-toggle:checked + .toggle-label:before {
  transform: translateX(20px); /* Adjust for the new switch width */
}

.logo {
  height:80px; /* Or whatever size you prefer */
}