h3
  {
  text-align: center;
  }

.App-link {
  color: #a8f9ff;
  text-decoration: underline;
}

.EmailWrap {
  display: flex; /* Enables flexbox layout */
  justify-content: center; /* Horizontally centers the content in the line */
  align-items: center; /* Vertically centers the content (if they do not wrap) */
  flex-wrap: wrap; /* Allows items to wrap onto the next line */
  padding-bottom: 20px;
  border-radius: 8px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .App-header {
    font-size: calc(8px + 2vmin);
  }

  .App-logo {
    height: 30vmin;
  }
}
